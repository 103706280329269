<template>

  <div>

    <ref-product-list-add-new
      :is-add-new-ref-product-sidebar-active.sync="isAddNewRefProductSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :product="refProduct"
      @refetch-data="refetchData"
      @add-ref-product="addRefProduct"
      @update-ref-product="updateRefProduct"
      :clear-ref-product-data="resetRefProductData"
      :ref-product-options="refProductOptions"
    />

    <!-- Filters -->
    <!-- <ref-products-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Нэг хуудсанд</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label> бичлэг харуулах</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Хайх..."
              />
              <b-button
                variant="primary"
                @click="isAddNewRefProductSidebarActive = true"
              >
                <span class="text-nowrap">Бүтээгдэхүүн нэмэх</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <el-table
        :data="refProductOptions"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        border
        default-expand-all>
        <el-table-column
          prop="name"
          label="Бүтээгдэхүүний нэр"
          sortable
          >
        </el-table-column>
        <el-table-column
          prop="created_at"
          label="Үүсгэсэн огноо"
          sortable>
        </el-table-column>
        <el-table-column
          prop="created_by"
          label="Үүсгэсэн хэрэглэгч"
          sortable>
        </el-table-column>
        <el-table-column
          prop="is_active"
          label="Төлөв">
          <template slot-scope="scope">
            <b-badge
              pill
              :variant="scope.row.is_active ? 'success': 'danger'"
            >
              {{ scope.row.is_active ? 'идэвхтэй': 'идэвхгүй' }}
            </b-badge>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="Үйлдэл"
          width="120">
          <template slot-scope="scope">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon __table_action_button"
              @click="getProduct(scope.row)" v-b-tooltip.hover.v-primary title="Дэлгэрэнгүй"
              size="sm"
            >
              <feather-icon icon="FileTextIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon __table_action_button"
              @click="getProduct(scope.row)" v-b-tooltip.hover.v-primary title="Засах"
              size="sm"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="scope.row.is_active ? 'outline-primary' : 'primary'"
            class="btn-icon __table_action_button"
            @click="confirmDelete(scope.row)" v-b-tooltip.hover.v-primary :title="'Устгах'"
            size="sm"
          >
            <feather-icon :icon="'TrashIcon'" />
          </b-button>
          </template>
        </el-table-column>
      </el-table>
      <b-modal id="delete-confirm-modal" @ok="deleteRefProduct" ok-title="Тийм"
      cancel-title="Үгүй">
        <template #modal-title>
          Устгахдаа итгэлтэй байна уу?
        </template>
        <p>Та энэ бүтээгдэхүүнийг устгахдаа итгэлтэй байна уу?</p>
      </b-modal>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Нийт {{ dataMeta.of }}-н {{ dataMeta.from }} - {{ dataMeta.to }} бичлэгийг  харуулж байна</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrganizations"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import RefProductsListFilters from './RefProductsListFilters.vue'
import useRefProductsList from './useRefProductsList'
import refProductStoreModule from '../refProductStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RefProductListAddNew from './RefProductListAddNew.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    RefProductsListFilters,
    RefProductListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
  setup() {
    const REF_PRODUCT_APP_STORE_MODULE_NAME = 'app-ref-product'

    // Register module
    if (!store.hasModule(REF_PRODUCT_APP_STORE_MODULE_NAME)) store.registerModule(REF_PRODUCT_APP_STORE_MODULE_NAME, refProductStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REF_PRODUCT_APP_STORE_MODULE_NAME)) store.unregisterModule(REF_PRODUCT_APP_STORE_MODULE_NAME)
    })

    const isAddNewRefProductSidebarActive = ref(false)

    const blankRefProduct = {
      parent: null,
      name: '',
      sort_order: '',
    }

    const refProduct = ref(JSON.parse(JSON.stringify(blankRefProduct)))
    const resetRefProductData = () => {
      refProduct.value = JSON.parse(JSON.stringify(blankRefProduct))
    }

    const roleOptions = [
      { label: 'Админ', value: 'admin' },
      { label: 'Эрхлэгч', value: 'author' },
      { label: 'Засварлагч', value: 'editor' },
      { label: 'Дэмжигч', value: 'maintainer' },
      { label: 'Дагагч', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Үндсэн', value: 'basic' },
      { label: 'Дэд', value: 'company1' },
      { label: 'Аж ахуй нэгж', value: 'enterprise' },
      { label: 'Баг', value: 'team' },
    ]

    const statusOptions = [
      // { label: 'Хүлээгдэж буй', value: 'pending' },
      { label: 'Идэвхитэй', value: 'true' },
      { label: 'Идэвхигүй', value: 'false' },
    ]

    const refProductOptions = ref([]);

    const fetchRefProductWithChildren = val => {
      console.log('val', val)
      store.dispatch('app-ref-product/fetchRefProductsWithChildren', val)
        .then(response => {
          refProductOptions.value = response.data.response
          // eslint-disable-next-line no-use-before-define
          // fetchRefPriorities()
        })
    }
    fetchRefProductWithChildren()

    const addRefProduct = val => {
      console.log('val', val)
      store.dispatch('app-ref-product/addRefProduct', val)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchRefProductWithChildren()
        })
    }

    const updateRefProduct = val => {
      store.dispatch('app-ref-product/editRefProduct', val)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchRefProductWithChildren()
        })
    }
    
    // const removeRefProduct = () => {
    //   store.dispatch('app-todo/removeTask', { id: task.value.id })
    //     .then(() => {
    //       // eslint-disable-next-line no-use-before-define
    //       fetchTasks()
    //     })
    // }

    const getProduct = productData => {
      console.log(productData)
      refProduct.value = productData
      isAddNewRefProductSidebarActive.value = true
    }

    const {
      fetchRefProducts,
      tableColumns,
      perPage,
      currentPage,
      totalOrganizations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRefProductListTable ,
      refetchData,

      // UI
      resolveOrganizationRoleVariant,
      resolveOrganizationRoleIcon,
      resolveOrganizationStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useRefProductsList()

    

    return {

      // Sidebar
      isAddNewRefProductSidebarActive,

      fetchRefProducts,
      fetchRefProductWithChildren,
      tableColumns,
      perPage,
      currentPage,
      totalOrganizations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRefProductListTable,
      refetchData,

      addRefProduct,
      updateRefProduct,

      // getPriority,
      resetRefProductData,
      refProduct,
      getProduct,

      // Filter
      avatarText,

      // UI
      resolveOrganizationRoleVariant,
      resolveOrganizationRoleIcon,
      resolveOrganizationStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,
      refProductOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },

  methods: {
    confirmDelete(refProductData) {
      this.refProductData = refProductData;
      this.$bvModal.show('delete-confirm-modal');
    },
    deleteRefProduct () {
      if(this.refProductData){
        store.dispatch('app-ref-product/deleteRefProduct', this.refProductData)
        .then(response => {
          console.log(response)
          if (response.data.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'BellIcon',
                variant: 'success',
              },
            })
            setTimeout(() => {
              this.$router.go()  // Refresh the current route
            }, 1000) 
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Алдаа гарлаа',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
        })
        .finally(() => {
          this.refProductData = null; // Reset the organization after deletion
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.__table_action_button {
  margin-right: 5px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
