import { render, staticRenderFns } from "./RefProductsList.vue?vue&type=template&id=78b3e3fd&scoped=true"
import script from "./RefProductsList.vue?vue&type=script&lang=js"
export * from "./RefProductsList.vue?vue&type=script&lang=js"
import style0 from "./RefProductsList.vue?vue&type=style&index=0&id=78b3e3fd&prod&lang=scss&scoped=true"
import style1 from "./RefProductsList.vue?vue&type=style&index=1&id=78b3e3fd&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78b3e3fd",
  null
  
)

export default component.exports